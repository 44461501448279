/**
 * useLocalStorage
 * localStorageはこのHookを使ってアクセスする。
 * 新たに項目を増やす場合は
 * 1. `StorageKeys` に `項目名: 'ストレージのキー'` を追加
 * 2. createStorageHook のジェネリクス引数に期待する返り値の型を追加
 * 3. createStorageHook の引数にデフォルト（フォールバック）値を追加
 */
import {
  createStorageHook,
  createInMemoryStorage,
} from '~/lib/createStorageHook'
import { isBrowser } from '~/lib/isBrowser'
import { SearchHistoryRecord } from './useSearchHistory'

// NOTE: localstorageで使用したいkeyを定義する
export const StorageKeys = {
  coordinate: 'wear_searchHistory_coordinate',
  user: 'wear_searchHistory_user',
  item: 'wear_searchHistory_item',
  shop: 'wear_searchHistory_shop',
  UserName: '__WEAR__USERNAME__',
  AccessToken: '__WEAR__ACCESS_TOKEN__',
  AccessTokenExpiration: '__WEAR__ACCESS_TOKEN__EXPIRATION__',
} as const

export type StorageKeys = (typeof StorageKeys)[keyof typeof StorageKeys]

const storage = isBrowser ? window.localStorage : undefined

export const useLocalStorage = createStorageHook<
  StorageKeys,
  {
    [StorageKeys.coordinate]: SearchHistoryRecord[]
    [StorageKeys.user]: SearchHistoryRecord[]
    [StorageKeys.item]: SearchHistoryRecord[]
    [StorageKeys.shop]: SearchHistoryRecord[]
    [StorageKeys.UserName]: string | null
    [StorageKeys.AccessToken]: string | null
    [StorageKeys.AccessTokenExpiration]: string | null
  }
>(storage ?? createInMemoryStorage(), {
  [StorageKeys.coordinate]: [],
  [StorageKeys.user]: [],
  [StorageKeys.item]: [],
  [StorageKeys.shop]: [],
  [StorageKeys.UserName]: null,
  [StorageKeys.AccessToken]: null,
  [StorageKeys.AccessTokenExpiration]: null,
})
