export const GtmTag: React.FC = () => {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5GQ5GD"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="アクセス解析タグ"
        />
      </noscript>
      {/* eslint-disable-next-line @next/next/next-script-for-ga */}
      <script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5GQ5GD');`,
        }}
      />
    </>
  )
}
