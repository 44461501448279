/* Auto generated by typedotenv */
export const NEXT_PUBLIC_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as string;
export const NEXT_PUBLIC_USE_MSW = process.env.NEXT_PUBLIC_USE_MSW as string;
export const NEXT_PUBLIC_CONNECT_API_URL = process.env.NEXT_PUBLIC_CONNECT_API_URL as string;
export const NEXT_PUBLIC_WEAR2_WEB_URL = process.env.NEXT_PUBLIC_WEAR2_WEB_URL as string;
export const NEXT_PUBLIC_WEAR2_AUTH_JP_URL = process.env.NEXT_PUBLIC_WEAR2_AUTH_JP_URL as string;
export const NEXT_PUBLIC_WEAR2_AUTH_CLIENT_ID = process.env.NEXT_PUBLIC_WEAR2_AUTH_CLIENT_ID as string;
export const NEXT_PUBLIC_LOGOUT_URL = process.env.NEXT_PUBLIC_LOGOUT_URL as string;
export const NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_URL = process.env.NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_URL as string;
export const NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_API_KEY = process.env.NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_API_KEY as string;
export const NEXT_PUBLIC_ZOZOTOWN_URL = process.env.NEXT_PUBLIC_ZOZOTOWN_URL as string;
export const NEXT_PUBLIC_COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string;
export const NEXT_PUBLIC_AD_SCRIPT = process.env.NEXT_PUBLIC_AD_SCRIPT as string;
export const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string;
export const SEND_SENTRY = process.env.SEND_SENTRY as string;
export const NEXT_PUBLIC_ANDROID_PACKAGE_NAME = process.env.NEXT_PUBLIC_ANDROID_PACKAGE_NAME as string;
export const NEXT_PUBLIC_ANDROID_FINGERPRINTS = process.env.NEXT_PUBLIC_ANDROID_FINGERPRINTS as string;
export const NEXT_PUBLIC_COMMA_SEPARATED_APP_IDS = process.env.NEXT_PUBLIC_COMMA_SEPARATED_APP_IDS as string;
export const NEXT_PUBLIC_COORDINATE_LIST_FLOATING_BANNER = process.env.NEXT_PUBLIC_COORDINATE_LIST_FLOATING_BANNER as string;
export const NEXT_PUBLIC_COORDINATE_DETAIL_ITEM_IMAGE = process.env.NEXT_PUBLIC_COORDINATE_DETAIL_ITEM_IMAGE as string;
export const NEXT_PUBLIC_ENABLE_COUPON_TARGET = process.env.NEXT_PUBLIC_ENABLE_COUPON_TARGET as string;
export const NEXT_PUBLIC_ENABLE_POST_AFFILIATE_WEAR2 = process.env.NEXT_PUBLIC_ENABLE_POST_AFFILIATE_WEAR2 as string;
export const NEXT_PUBLIC_RECAPTCHA_CHECKBOX_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_CHECKBOX_SITE_KEY as string;
export const NEXT_PUBLIC_MISSION_LP_ID = process.env.NEXT_PUBLIC_MISSION_LP_ID as string;
