import { AffiliatePostData } from './types'

const affiliatePath = '/sp/common/json/affiliate.json'

export const postAffiliate = (data: AffiliatePostData) => {
  const params = new URLSearchParams(
    Object.entries(data).map(([key, val]) => [
      key,
      val === null || val === undefined ? '' : val.toString(),
    ])
  )
  fetch(affiliatePath, { method: 'POST', body: params }).catch(() => undefined)
}
