export const createInMemoryStorage = (
  init?: Iterable<readonly [string, string | null]> | null
): Storage => {
  const map = new Map<string, string | null>(init)
  return {
    get length() {
      return map.size
    },
    clear() {
      map.clear()
    },
    getItem(key) {
      return map.get(key) ?? null
    },
    setItem(key, value) {
      map.set(key, value)
    },
    removeItem(key) {
      map.delete(key)
    },
    key(index) {
      return Array.from(map.keys())[index] ?? null
    },
  }
}
