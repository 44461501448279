import { useRouter } from 'next/router'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { initAffiliate } from '~/features/shared/affiliate'

export const InitAffiliateContext = createContext(false)

export const AffiliateInitProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false)
  const { pathname, query } = useRouter()
  useEffect(() => {
    initAffiliate(pathname, query)
    setIsReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InitAffiliateContext.Provider value={isReady}>
      {children}
    </InitAffiliateContext.Provider>
  )
}
